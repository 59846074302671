<template>
   <v-dialog v-model="show" max-width="750px" persistent>
      <v-card>
         <v-layout wrap align-start>
            <v-flex xs12>
               <v-snackbar
                  v-model="snackbar"
                  :color="snackColor"
                  :right="true"
                  :bottom="true"
                  :timeout="4000"
               >
                  {{ snackText }}
                  <template #action="{ attrs }">
                     <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                     >
                        Cerrar
                     </v-btn>
                  </template>
               </v-snackbar>

               <!-- DIALOG EDITAR DETALLE BECA -->
               <v-dialog v-model="dialogEditar" persistent max-width="600px">
                  <v-form ref="formBecas" v-model="validBeca" lazy-validation>
                     <v-card>
                        <v-card-text
                           class="text--primary"
                           style="margin-top: -20px"
                        >
                           <v-container grid-list-md>
                              <v-layout wrap>
                                 <v-flex xs12>
                                    <span class="text-h5 font-weight-medium">{{
                                       formTitle
                                    }}</span>
                                 </v-flex>
                              </v-layout>
                              <v-layout wrap>
                                 <v-flex xs12 sm2>
                                    <v-text-field
                                       v-model="periodoLectivo"
                                       readonly
                                       color="primary"
                                       label="Ciclo"
                                    ></v-text-field>
                                 </v-flex>
                                 <v-flex xs12 sm2>
                                    <v-text-field
                                       v-model="cuota"
                                       readonly
                                       color="primary"
                                       label="Cuota"
                                    ></v-text-field>
                                 </v-flex>
                                 <v-flex xs12 sm8>
                                    <v-text-field
                                       v-model="npe"
                                       readonly
                                       color="primary"
                                       label="Npe"
                                    ></v-text-field>
                                 </v-flex>
                                 <v-flex xs12>
                                    <v-file-input
                                       v-model="archivosAdjuntos"
                                       color="primary"
                                       :rules="adjuntosRules"
                                       label="Archivos adjuntos"
                                       placeholder="Selecciona tu archivo"
                                       prepend-icon="fa-paperclip"
                                       accept=".pdf"
                                       outlined
                                       multiple
                                       counter
                                       :show-size="1000"
                                    >
                                       <template #selection="{ text }">
                                          <v-chip
                                             color="primary"
                                             dark
                                             label
                                             small
                                          >
                                             {{ text }}
                                          </v-chip>
                                       </template>
                                    </v-file-input>
                                 </v-flex>
                              </v-layout>
                           </v-container>
                        </v-card-text>

                        <v-card-actions
                           style="
                              margin-top: -40px;
                              margin-right: 20px;
                              padding-bottom: 20px;
                           "
                        >
                           <v-spacer></v-spacer>
                           <v-btn color="primary" text @click.native="close"
                              >Cancelar</v-btn
                           >
                           <v-btn
                              color="primary white--text"
                              :disabled="!validBeca"
                              @click.native="save"
                              >Enviar</v-btn
                           >
                        </v-card-actions>
                     </v-card>
                  </v-form>
               </v-dialog>

               <!-- DIALOG ANULA COMPROBANTE -->
               <v-dialog v-model="dialogAnular" persistent max-width="500px">
                  <v-form ref="formEliminarAbono">
                     <v-card>
                        <v-card-text
                           class="text--primary"
                           style="margin-top: -20px"
                        >
                           <v-container grid-list-md>
                              <v-layout wrap>
                                 <v-flex xs12>
                                    <span class="text-h5 font-weight-medium"
                                       >Anular comprobante</span
                                    >
                                 </v-flex>
                                 <v-flex xs12>
                                    <span
                                       class="font-weight-regular"
                                       style="font-size: 20px"
                                       >¿Esta seguro que desea anular el
                                       comprobante?</span
                                    >
                                    <v-layout wrap style="margin-top: 10px">
                                       <v-flex xs12 sm6>
                                          <div style="font-size: 18px">
                                             Cuota:
                                          </div>
                                          <v-card
                                             flat
                                             color="red lighten-5"
                                             class="text-center elevation-1"
                                             style="
                                                border-radius: 10px;
                                                padding: 15px 25px;
                                             "
                                          >
                                             <span
                                                class="red--text text--darken-2 text-h6"
                                                >{{ cuota }}</span
                                             >
                                          </v-card>
                                       </v-flex>
                                       <v-flex xs12 sm6>
                                          <div style="font-size: 18px">
                                             Periodo Lectivo:
                                          </div>
                                          <v-card
                                             flat
                                             color="red lighten-5"
                                             class="text-center elevation-1"
                                             style="
                                                border-radius: 10px;
                                                padding: 15px 25px;
                                             "
                                          >
                                             <span
                                                class="red--text text--darken-2 text-h6"
                                                >{{ periodoLectivo }}</span
                                             >
                                          </v-card>
                                       </v-flex>
                                    </v-layout>
                                 </v-flex>
                                 <v-flex xs12 text-center>
                                    <v-icon
                                       color="primary"
                                       size="70"
                                       class="mt-2 mb-2"
                                    >
                                       fa-ban
                                    </v-icon>
                                 </v-flex>
                              </v-layout>
                           </v-container>
                        </v-card-text>

                        <v-card-actions
                           style="margin-top: -30px; padding-bottom: 20px"
                        >
                           <v-spacer></v-spacer>
                           <v-btn
                              color="primary"
                              dark
                              outlined
                              min-width="150"
                              @click.native="anularComprobante"
                              >Si</v-btn
                           >
                           <v-btn
                              color="primary"
                              dark
                              min-width="150"
                              @click.native="closedialogAnular"
                              >No</v-btn
                           >
                           <v-spacer></v-spacer>
                        </v-card-actions>
                     </v-card>
                  </v-form>
               </v-dialog>

               <v-dialog v-model="dialogEnviandoComp" persistent width="300">
                  <v-card color="primary" dark class="pt-5 pb-2">
                     <v-card-text style="color: white">
                        Enviando comprobante
                        <v-progress-linear
                           indeterminate
                           color="white"
                           class="mb-0"
                        ></v-progress-linear>
                     </v-card-text>
                  </v-card>
               </v-dialog>

               <v-card elevation="0">
                  <v-toolbar color="primary" dark>
                     <v-toolbar-title style="font-weight: 500"
                        >Subir comprobantes de becas</v-toolbar-title
                     >
                     <v-spacer></v-spacer>
                     <div class="mt-8 ml-3" style="width: 150px">
                        <v-autocomplete
                           v-model="selectCiclo"
                           label="Ciclo"
                           :items="periodosLectivos"
                        >
                        </v-autocomplete>
                     </div>
                  </v-toolbar>

                  <!-- DATATABLE detalles -->
                  <v-data-table
                     :headers="headers"
                     :items="detalles"
                     :search="search"
                     :footer-props="{
                        itemsPerPageOptions: [-1],
                        itemsPerPageText: 'Filas por página:'
                     }"
                     :items-per-page="-1"
                     hide-default-footer
                     :loading="loadingDetalles"
                     loading-text="Listando detalles..."
                     no-results-text="Búsqueda sin resultados"
                     class="elevation-0"
                  >
                     <template #item.npe="{ item }">
                        <div v-if="$vuetify.breakpoint.smAndUp">
                           {{ item.npe }}
                        </div>
                        <div
                           v-if="$vuetify.breakpoint.xsOnly"
                           style="width: 150px"
                        >
                           <v-text-field
                              color="primary"
                              readonly
                              :value="item.npe"
                           >
                              <v-icon
                                 v-if="$vuetify.breakpoint.xsOnly"
                                 slot="prepend"
                                 color="primary"
                                 @click="copyToClip(item.npe)"
                                 >far fa-copy</v-icon
                              >
                           </v-text-field>
                        </div>
                     </template>

                     <template #item.opciones="{ item }">
                        <v-tooltip
                           v-if="
                              item.comprobantePath != null &&
                              item.fechaDePago != 'PAGO BANCO' &&
                              checkIsAdmin(oidcUser)
                           "
                           top
                           max-width="300"
                           color="primary"
                        >
                           <template #activator="{ on }">
                              <v-btn
                                 text
                                 small
                                 fab
                                 color="primary"
                                 v-on="on"
                                 @click="showRemoverAdjunto(item)"
                              >
                                 <v-icon>fa-ban</v-icon>
                              </v-btn>
                           </template>
                           <span style="font-size: 15px">
                              Anular archivo enviado
                           </span>
                        </v-tooltip>
                        <v-btn
                           v-if="
                              item.comprobantePath == null &&
                              item.fechaDePago != 'PAGO BANCO'
                           "
                           text
                           small
                           fab
                           color="amber darken-3"
                           :disabled="
                              item.comprobantePath != null || !item.facturado
                           "
                           @click="item.facturado ? editItem(item) : false"
                        >
                           <v-icon>fa-upload</v-icon>
                        </v-btn>
                        <v-tooltip v-else top max-width="300" color="primary">
                           <template #activator="{ on }">
                              <v-btn
                                 v-if="item.comprobantePath != null && oidcUser"
                                 text
                                 small
                                 fab
                                 color="primary"
                                 v-on="on"
                                 @click="descargarAdjunto(item)"
                              >
                                 <v-icon>fas fa-download</v-icon>
                              </v-btn>
                              <v-icon v-else color="primary" v-on="on">
                                 {{
                                    item.fechaDePago != 'PAGO BANCO'
                                       ? 'fa-check'
                                       : 'fa-university'
                                 }}
                              </v-icon>
                           </template>
                           <span style="font-size: 15px">
                              {{
                                 item.fechaDePago != 'PAGO BANCO'
                                    ? 'Descargar archivo enviado'
                                    : 'Tramitó en Colecturía'
                              }}
                           </span>
                        </v-tooltip>
                     </template>
                     <template #item.ciclo>
                        {{ periodoLectivo }}
                     </template>
                     <!-- Si no hay datos, mostrar boton para listar de nuevo -->
                     <template slot="no-data">
                        <div v-if="detalles.length == 0">
                           <v-container>
                              <v-layout wrap align-center>
                                 <v-flex xs12 class="text-center">
                                    <v-btn color="primary" @click="reset"
                                       >Resetear</v-btn
                                    >
                                 </v-flex>
                              </v-layout>
                           </v-container>
                        </div>
                     </template>
                  </v-data-table>
               </v-card>
            </v-flex>
            <v-flex xs12 text-center px-4 py-2>
               <v-btn block text color="primary" @click="closeDialog">
                  Salir
               </v-btn>
            </v-flex>
         </v-layout>
      </v-card>
   </v-dialog>
</template>

<script>
import axios from 'axios'
import formatters from '../mixins/formattersLib'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'Comprobante',
   directives: {
      mask
   },
   mixins: [formatters],
   props: {
      carnet: {
         type: String,
         default: ''
      },
      periodosLectivos: {
         type: Array,
         default: null
      },
      value: Boolean
   },
   data: () => ({
      // Variables
      dialogEditar: false,
      dialogAnular: false,
      editedIndex: -1,
      loadingDetalles: false,
      selectCiclo: '',
      headers: [
         {
            text: 'Cuota',
            value: 'numeroCuota',
            class: 'titulo',
            align: 'center',
            sortable: false
         },
         {
            text: 'Ciclo',
            value: 'periodoLectivo',
            class: 'titulo',
            align: 'center',
            sortable: false
         },
         { text: 'Npe', value: 'npe', class: 'titulo', sortable: false },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'center',
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      detalles: [],
      swGeneraVenta: false,

      validBeca: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      rutaArchivoAdjunto: '',

      // Propiedades bodega
      becaId: '',
      cuota: '',
      npe: '',
      periodoLectivo: '',
      becaDetalleId: '',
      archivosAdjuntos: [],
      dialogEnviandoComp: false,

      // Reglas de validacion de formularios
      adjuntosRules: [
         (files) => files.length > 0 || 'Selecciona un archivo.',
         (files) => files.length <= 1 || 'Solo se puede subir un archivo.',
         (files) =>
            !files ||
            !files.some((file) => file.type != 'application/pdf') ||
            'Solo se permiten archivos PDF',
         (files) =>
            !files ||
            !files.some((file) => file.size > 15000000) ||
            'Los archivos adjuntos no pueden pesar mas de 15MB!'
      ]
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return 'Ajuntar Comprobante de Beca'
      },
      show: {
         get() {
            return this.value
         },
         set(value) {
            this.$emit('input', value)
         }
      }
   },
   watch: {
      selectCiclo() {
         this.listarBecas()
      },
      show(val) {
         if (val == true) {
            this.selectCiclo = this.periodosLectivos[0]
            this.listarBecas()
         }
      }
   },
   methods: {
      anularComprobante() {
         axios
            .put('api/beca/' + this.becaId + '/detalle/' + this.becaDetalleId, {
               numeroCuota: this.cuota,
               npe: this.npe,
               comprobantePath: null
            })
            .then(() => {
               this.listarBecas()
               this.snackText = 'Comprobante anulado.'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
               this.closedialogAnular()
            })
            .catch((error) => {
               console.log(error)
            })
      },
      showRemoverAdjunto(item) {
         this.becaDetalleId = item.becaDetalleId
         this.periodoLectivo = item.periodoLectivo
         this.cuota = item.numeroCuota
         this.npe = item.npe
         this.dialogAnular = true
      },
      closedialogAnular() {
         this.clear()
         this.dialogAnular = false
      },
      checkIsAdmin(user) {
         if (user) {
            return user.role == 'admin' ? true : false
         } else {
            return false
         }
      },
      // Copiar al portapapeles
      copyToClip(value) {
         this.copyStringToClipboard(value)
         this.snackText = 'NPE copiado al portapapeles'
         this.snackColor = 'snackInfo'
         this.snackbar = true
      },
      // Cerrar ventana de dialogo principal
      closeDialog() {
         this.show = false
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarBecas() {
         this.loadingDetalles = true
         this.detalles = []
         // Obteniendo detalles de ciclos con becas
         this.detalles = []
         // Obteniendo detalles
         axios
            .get(
               `api/Beca?Carnet=${this.carnet}&PeriodoLectivo=${this.selectCiclo}`
            )
            .then((response) => {
               this.becaId = response.data[0].becaId
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               axios
                  .get('api/beca/' + this.becaId + '/detalle')
                  .then((response) => {
                     this.detalles = response.data
                     this.detalles.sort(this.dynamicSort('numeroCuota'))
                     this.loadingDetalles = false
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            })
      },
      // Si no cargaron bien las detalles en el datatable se puede resetear
      reset() {
         this.listarBecas()
      },
      // Cargando informacion del item a editar
      editItem(item) {
         // Obteniendo valores para bodega segun id
         axios
            .get('api/Beca/' + this.becaId + '/detalle/' + item.becaDetalleId)
            .then((response) => {
               this.result = response.data
               this.becaDetalleId = this.result.becaDetalleId
               this.periodoLectivo = item.periodoLectivo
               this.cuota = this.result.numeroCuota
               this.npe = this.result.npe
               this.rutaArchivoAdjunto = this.result.comprobantePath
            })
            .catch((error) => {
               console.log(error)
            })
         this.editedIndex = 1
         this.dialogEditar = true
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogAnular = false
         this.dialogEditar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar bodega
      save() {
         if (this.$refs.formBecas.validate()) {
            // Codigo para actualizar/editar
            this.dialogEnviandoComp = true
            let formData = new FormData()
            for (let file of this.archivosAdjuntos) {
               formData.append('file', file, file.name)
            }
            axios
               .put(
                  'api/beca/' +
                     this.becaId +
                     '/detalle/' +
                     this.becaDetalleId +
                     '/subircomprobante',
                  formData
               )
               .then(() => {
                  this.listarBecas()
                  this.close()
                  this.snackText = 'Comprobante adjuntado exitosamente!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  this.dialogEnviandoComp = false
               })
               .catch((error) => {
                  console.log(error)
               })
         }
      },
      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formBecas.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.cuota = ''
         this.npe = ''
         this.archivosAdjuntos = []
      },
      descargarAdjunto(item) {
         this.rutaArchivoAdjunto = item.comprobantePath

         if (this.rutaArchivoAdjunto.trim() != '') {
            axios
               .get(
                  'api/FormularioPlanPago/ArchivoAdjunto?filepath=' +
                     this.rutaArchivoAdjunto,
                  {
                     responseType: 'arraybuffer',
                     headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/pdf'
                     }
                  }
               )
               .then((response) => {
                  this.snackText = 'Descargando archivo adjunto'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true

                  const url = window.URL.createObjectURL(
                     new Blob([response.data])
                  )
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute(
                     'download',
                     this.rutaArchivoAdjunto.match(/[^\\]*(?=[.][a-zA-Z]+$)/) +
                        '.pdf'
                  ) //or any other extension
                  document.body.appendChild(link)
                  link.click()
               })
               .catch(() => {
                  this.snackText = 'No se pudo descargar el archivo adjunto'
                  this.snackColor = 'snackError'
                  this.snackbar = true
               })
         }
      }
   }
}
</script>
