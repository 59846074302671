<template>
   <v-layout align-start justify-center>
      <v-flex xs8>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- DIALOG CREAR / EDITAR BECA -->
         <v-dialog v-model="dialogPatrocinador" persistent max-width="600px">
            <v-form
               ref="formPatrocinador"
               v-model="validPatrocinador"
               lazy-validation
            >
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium">{{
                                 formTitle
                              }}</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="nombre"
                                 :rules="rulesNombre"
                                 color="primary"
                                 label="nombre"
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="abreviatura"
                                 :rules="rulesAbreviatura"
                                 color="primary"
                                 label="abreviatura"
                              ></v-text-field>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn color="primary" text @click.native="close"
                        >Cancelar</v-btn
                     >
                     <v-btn
                        color="primary white--text"
                        :disabled="!validPatrocinador"
                        @click.native="save"
                        >Guardar</v-btn
                     >
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG ELIMINAR BODEGA -->
         <v-dialog v-model="dialogEliminar" persistent max-width="600px">
            <v-form ref="formEliminar">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Remover patrocinador</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro que desea remover al
                                 patrocinador?</span
                              >
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ nombre }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="
                                          border-radius: 10px;
                                          padding: 15px 25px;
                                       "
                                    >
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ abreviatura }}</span
                                       >
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                                 >fa-trash-alt</v-icon
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="removerBecas"
                        >Si</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="close"
                        >No</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>
         <!-- DIALOG SUBIR COMPROBANTE DE BECA -->
         <ComprobanteBecario
            v-model="dialogBecaComp"
            :nombre="nombre"
            :periodos-lectivos="periodosLectivos"
         />

         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Patrocinadores de becas externas</v-toolbar-title
               >
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-3"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>
               <v-tooltip bottom max-width="300" color="primary">
                  <template #activator="{ on }">
                     <v-btn
                        text
                        icon
                        color="white darken-3"
                        v-on="on"
                        @click="nuevaBeca()"
                     >
                        <v-icon>fas fa-plus</v-icon>
                     </v-btn>
                  </template>
                  <span style="font-size: 15px"> Nueva Beca </span>
               </v-tooltip>
            </v-toolbar>

            <!-- DATATABLE patrocinadores -->
            <v-data-table
               :headers="headers"
               :items="patrocinadores"
               :loading="loadingPatrocinadores"
               loading-text="Listando patrocinadores, por favor espere..."
               no-data-text="No se encontraron patrocinadores para el abreviatura seleccionado."
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #item.opciones="{ item }">
                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           v-on="on"
                           @click="editItem(item)"
                        >
                           <v-icon>fa-pen</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Editar </span>
                  </v-tooltip>

                  <v-tooltip bottom max-width="300" color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="primary"
                           v-on="on"
                           @click="deleteItem(item)"
                        >
                           <v-icon>fa-trash-alt</v-icon>
                        </v-btn>
                     </template>
                     <span style="font-size: 15px"> Eliminar </span>
                  </v-tooltip>
               </template>

               <!-- Si no hay datos, mostrar boton para listar de nuevo -->
               <template slot="no-data">
                  <div v-if="patrocinadores.length == 0">
                     <v-container>
                        <v-layout wrap align-center>
                           <v-flex xs12 class="text-center">
                              <v-btn color="primary" @click="reset"
                                 >Resetear</v-btn
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </div>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'
import ComprobanteBecario from '../components/ComprobanteBecario'

export default {
   name: 'Patrocinadores',
   components: {
      ComprobanteBecario: ComprobanteBecario
   },
   directives: {
      mask
   },
   data: () => ({
      // Variables
      dialogPatrocinador: false,
      dialogEliminar: false,
      editedIndex: -1,
      headers: [
         { text: 'Nombre', value: 'nombre', class: 'titulo' },
         { text: 'Abreviatura', value: 'abreviatura', class: 'titulo' },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      pagination: {},
      result: [],
      patrocinadores: [],
      swGeneraVenta: false,
      dialogBecaComp: false,
      cmbCiclosBecas: [],
      selectCicloBecas: '',
      loadingPatrocinadores: false,

      validPatrocinador: true,
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      selectInstitucion: '',
      cmbInstitucion: [],

      // Propiedades bodega
      institucionPatrocinadoraId: '',
      nombre: '',
      abreviatura: '',
      periodosLectivos: [],
      selectTipo: '',
      cmbTipoBeca: [],
      talonario: '',
      apellidos: '',
      email: '',
      direccion: '',
      dui: '',
      nit: '',

      // Reglas de validacion de formularios
      rulesNombre: [(v) => !!v || 'Ingrese una nombre'],
      rulesAbreviatura: [(v) => !!v || 'Ingrese una abreviatura'],
      rulesInstitucion: [(v) => !!v || 'Ingrese una Institución'],
      rulesTalonario: [(v) => !!v || 'Ingrese una talonario']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1
            ? 'Nuevo patrocinador'
            : 'Editar patrocinador'
      }
   },
   watch: {},
   created() {
      //Validando acceso al componente
      if (this.permController(12, 'ver')) {
         this.listarPatrocinadores()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarPatrocinadores() {
         this.patrocinadores = []
         this.loadingPatrocinadores = true
         // Obteniendo patrocinadores
         axios
            .get('api/InstitucionPatrocinadora')
            .then((response) => {
               this.patrocinadores = response.data
               this.loadingPatrocinadores = false
            })
            .catch((error) => {
               console.log(error)
               this.loadingPatrocinadores = false
            })
      },
      // Si no cargaron bien las patrocinadores en el datatable se puede resetear
      reset() {
         this.listarPatrocinadores()
      },

      // Cargando informacion del item a editar
      editItem(item) {
         // Obteniendo valores para bodega segun id
         axios
            .get(
               'api/InstitucionPatrocinadora/' + item.institucionPatrocinadoraId
            )
            .then((response) => {
               this.result = response.data
               this.institucionPatrocinadoraId =
                  this.result.institucionPatrocinadoraId
               this.nombre = this.result.nombre
               this.abreviatura = this.result.abreviatura
            })
            .catch((error) => {
               console.log(error)
            })
         this.editedIndex = 1
         this.dialogPatrocinador = true

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Muestra el dialog para remover unabodega
      deleteItem(item) {
         this.dialogEliminar = true
         this.nombre = item.nombre
         this.abreviatura = item.abreviatura
         this.institucionPatrocinadoraId = item.institucionPatrocinadoraId

         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },
      // Cerrar ventana de dialogo
      close() {
         this.dialogPatrocinador = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },
      // Guardar/Actulizar bodega
      save() {
         if (this.$refs.formPatrocinador.validate()) {
            if (this.editedIndex > -1) {
               // Codigo para actualizar/editar
               axios
                  .put(
                     'api/InstitucionPatrocinadora/' +
                        this.institucionPatrocinadoraId,
                     {
                        nombre: this.nombre,
                        abreviatura: this.abreviatura
                     }
                  )
                  .then(() => {
                     this.listarPatrocinadores()
                     this.close()
                     this.snackText = 'Beca editada exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            } else {
               // Codigo para guardar
               axios
                  .post('api/InstitucionPatrocinadora', {
                     nombre: this.nombre,
                     abreviatura: this.abreviatura
                  })
                  .then(() => {
                     this.listarPatrocinadores()
                     this.close()
                     this.snackText = 'Beca agregada exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                  })
                  .catch((error) => {
                     console.log(error)
                  })
            }
         }
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formPatrocinador.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.nombre = ''
         this.abreviatura = ''
         this.talonario = ''
         this.selectInstitucion = ''
      },
      nuevaBeca() {
         this.dialogPatrocinador = true
      },
      removerBecas() {
         // Enviando metodo delete
         axios
            .delete(
               'api/InstitucionPatrocinadora/' + this.institucionPatrocinadoraId
            )
            .then(() => {
               this.listarPatrocinadores()
               this.close()
               this.snackText = 'Beca removida exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch((error) => {
               console.log(error)
            })
      },
      showDetalles(item) {
         this.nombre = item.nombre
         this.periodosLectivos = []
         this.periodosLectivos.push(item.abreviatura)
         this.dialogBecaComp = true
      }
   }
}
</script>
